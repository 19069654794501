import React from 'react'
import { Loading } from 'modules/loading/Loading'
import {
  Box, Flex, Button, Spacer, HStack,
} from '@chakra-ui/react'
import { Logo } from './Logo'
import { useNavigate } from 'react-router-dom'
import { useCurrentUserId } from 'features/users/useCurrentUserId'
import { useAuth } from 'features/users/useAuth'

export interface LayoutProps {
  children?: React.ReactChild|React.ReactChild[]
  logoLink?: string
  logoLinkExternal?: boolean
  isLoading?: boolean
  hideAuthBtns?: boolean
}

export function Layout ({ children, isLoading, logoLink, logoLinkExternal, hideAuthBtns }: LayoutProps) {
  const navigate = useNavigate()
  const auth = useAuth()
  const [userId, userIdLoading] = useCurrentUserId()

  return (
    <Flex height='100%' flexDirection='column'>
      <HStack p={2}>
        <Logo to={logoLink} external={logoLinkExternal} />
        <Spacer />
        {!hideAuthBtns && (
          <HStack spacing={2}>
            {!userId && !userIdLoading && (
              <Button onClick={async () => {
                navigate('/login')
              }}>Login</Button>)}
            {userId && !userIdLoading && (
              <Button onClick={async () => {
                await auth.logout()
                navigate('/login')
              }}>Logout</Button>
            )}
          </HStack>
        )}

      </HStack>
      <Box flex='1 1 auto'>
        {isLoading
          ? (
            <Box mt={10} >
              <Loading loading center data-test='layout-loading' />
            </Box>
          )
          : children}
      </Box>
    </Flex>
  )
}
