import React from 'react'
import {
  HStack, Img, Heading, Link,
} from '@chakra-ui/react'
import logo from 'img/logo.svg'

export interface LogoProps {
  to?: string|null
  external?: boolean
}

export function Logo ({ to, external }: LogoProps) {
  const logoEl = (
    <HStack spacing={1} height={'40px'}>
      <Img src={logo} height={'28px'} />
    </HStack>
  )
  return to ? <Link isExternal={external} href={to}>{logoEl}</Link> : logoEl
}
