import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import {
  Button, Box, Heading, Stack, Center,
  FormControl, FormLabel, Input, InputProps, InputGroup, InputRightElement, InputLeftElement,
  FormHelperText,
  InputLeftAddon,
  Spinner,
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons'
import { nanoid } from 'nanoid'
import { useForm, useWatch, useController, FormProvider, useFormContext } from 'react-hook-form'
import Label from 'modules/base/Label'
import { useAsyncCallback } from 'modules/common/useAsyncCallback'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout } from 'features/common/Layout'
import { useCreateWorkspaceMutation, useGetWorkspaceQuery } from 'apollo'

const {
  REACT_APP_WEB_URL = 'https://zeplo.io',
} = process.env

export interface WorkspaceCreateData {
  name: string
  slug: string
}

export function WorkspaceCreate () {
  const navigate = useNavigate()
  const [createWorkspace] = useCreateWorkspaceMutation()

  const form = useForm<WorkspaceCreateData>()
  const {
    register,
    handleSubmit,
  } = form

  const onSubmit = useAsyncCallback(async ({ name, slug }: WorkspaceCreateData) => {
    const resp = await createWorkspace({
      variables: {
        input: {
          id: slug,
          name,
        },
      },
      refetchQueries: 'all',
    })

    const id = resp?.data?.createWorkspace?.id

    if (id) navigate(`/w/${id}`)
  })

  return (
    <Layout>
      <FormProvider {...form}>
        <Center height='50%'>
          <Box maxW='32em' minW='20em'>
            <form
              onSubmit={handleSubmit(onSubmit.execute)}
            >
              <Stack spacing={6}>
                <Heading size='xl'>Create Workspace</Heading>
                <FormControl id='name'>
                  <FormLabel fontWeight='bold'>Name</FormLabel>
                  <Input  isRequired type='text' placeholder='Name of workspace' data-type='text' {...register('name')} />
                </FormControl>
                <SlugInput
                  data-cy='slug'
                  name='slug'
                  slugForName='name'
                  placeholder='ID'
                />
                <Button
                  size='lg'
                  isLoading={onSubmit.loading}
                  type='submit'
                  colorScheme='brand'
                >
                  Create Workspace
                </Button>
              </Stack>
            </form>
          </Box>
        </Center>
      </FormProvider>
    </Layout>
  )
}

export interface SlugInputProps extends InputProps {
  name: string
  slugForName: string
}


const normalize = (str: string) => str && str.toLowerCase().replace(/[,'"./]/g, '').trim().split(' ').join('-')

function SlugInput ({ name, slugForName, ...props }: SlugInputProps) {
  const [isFocus, setIsFocus] = useState(false)
  const { control, setValue } = useFormContext()
  const { field, fieldState } = useController({ name })

  const nameValue = useWatch({ name: slugForName, control })
  const randomId = useMemo(() => nanoid(5), [])

  const { data, loading } = useGetWorkspaceQuery({
    variables: { id: field.value },
    skip: !field.value,
  })
  const workspace = data?.getWorkspace

  useEffect(() => {
    if (fieldState.isTouched || isFocus) return
    setValue(name, normalize(nameValue))
  }, [field, fieldState.isTouched, isFocus, name, nameValue, setValue])

  const validateStatus = loading
    ? 'validating'
    : (workspace ? 'error' : 'success')

  const suggestedId = `${normalize(nameValue ?? 'teapot')}-${randomId}`
  const help = validateStatus === 'error'
    ? (
      <FormHelperText css={styles.try} onClick={() => field.onChange(suggestedId)}>
        ID taken - try <Box as='span' css={styles.try}>{suggestedId}</Box>?
      </FormHelperText>
    )
    : ' '

  let statusEl = null
  if (loading) statusEl = <Spinner />
  else if (!workspace) statusEl = <CheckIcon color='green.500' />
  else if (field.value) statusEl =  <WarningIcon color='red.500' />

  return (
    <Box data-cy='slug-container'>
      <Box css={styles.slug}>

        <FormControl>
          <FormLabel>URL</FormLabel>
          <InputGroup>
            <InputLeftAddon color='gray.600' children={`${REACT_APP_WEB_URL}/w/`} />
            <Input {...field} onFocus={() => setIsFocus(true)} />
            {field.value && <InputRightElement children={statusEl} />}
          </InputGroup>
          {help}
        </FormControl>
      </Box>
    </Box>
  )
}

const styles = {
  label: css`
    display: block;
    font-weight: 600;
    margin-bottom: 0.1em;
  `,
  form: css`
    max-width: 300px;
  `,
  field: css`
    margin-bottom: 1.5em;
  `,
  slug: css`
    display: flex;
  `,
  prefix: css`
    line-height: 32px;
    margin-right: 0.3em;
    color: #777;
  `,
  try: css`
    font-size: 0.9em;
    color: #999;
    cursor: pointer;
    span {
      color: #3d7bc1;
    }
  `,
}
