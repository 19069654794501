import { useEffect } from 'react'
import { useMeQuery, MeQuery } from 'apollo'
import { ApolloQueryResult } from '@apollo/client'
import { useCurrentUserId } from './useCurrentUserId'
import cleanDeep from 'clean-deep'
import posthog from 'posthog-js'

export function useCurrentUser (): [MeQuery['me']|null, boolean, () => Promise<ApolloQueryResult<MeQuery>>] {
  const [userId, userIdLoading] = useCurrentUserId()
  const { data: meQuery, loading, refetch } = useMeQuery({
    skip: !userId,
    fetchPolicy: 'network-only',
  })

  // Identify incase it was missed
  const email = meQuery?.me?.email
  useEffect(() => {
    if (!email || !userId) return
    posthog.identify(userId, { email })
  }, [email, userId])

  return [meQuery?.me ? cleanDeep(meQuery?.me) : null, userIdLoading || loading, refetch]
}
