import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Box, Heading, Container, Center, Stack } from '@chakra-ui/react'
import { Layout } from 'features/common/Layout'
import { LoginCodeForm } from './LoginCodeForm'

export default function LoginCodePage () {
  const location = useLocation()

  const locationState = location.state as unknown as { email?: string }
  const email = locationState?.email ?? queryString.parse(location.search).email as string

  return (
    <Layout>
      <Box mt={4}>
        <Center>
          <Container maxWidth='25em' padding='1em'>
            <Stack spacing={3}>
              <Heading data-cy='title'>Enter login code</Heading>
              <Box data-cy='desc'>We&apos;ve sent an email to ({email}), copy and paste the code here (or use link in email).</Box>
              <LoginCodeForm email={email} />
            </Stack>
          </Container>
        </Center>
      </Box>
    </Layout>
  )
}
