import * as React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import posthog from 'posthog-js'
import ReactGA from 'react-ga'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'

const {
  REACT_APP_VERSION,
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENV_NAME,
  NODE_ENV,
} = process.env

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: `v${REACT_APP_VERSION}`,
    environment: REACT_APP_ENV_NAME,
  })

  const TRACKING_ID = 'UA-140920867-2'
  ReactGA.initialize(TRACKING_ID)
}

// Event capturing etc
posthog.init('phc_bnhdghRYJEmiSxn5knZPsZuf3g2k7LdBAEKQkPEB4Hw', { api_host: 'https://app.posthog.com', debug: true })
// posthog.opt_in_capturing()
if (process.env.REACT_APP_ENV_NAME !== 'production') {
  posthog.opt_out_capturing()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
