import { useEffect, useMemo, createContext, ReactChild, useCallback, useState, useContext } from 'react'
import { useCurrentUser } from 'features/users/useCurrentUser'

export const CrispContext = createContext({
  show: () => {},
  hide: () => {},
  open: () => {},
  visible: false,
})

export interface CrispProviderProps {
  children: ReactChild[]|ReactChild
}

// See docs at https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/
export function CrispProvider ({ children }: CrispProviderProps) {
  const $crisp = (window as any).$crisp
  const [user] = useCurrentUser()
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!$crisp) return

    if (!visible) {
      $crisp.push(['on', 'chat:closed', () => {
        $crisp.push(['do', 'chat:hide'])
      }])
    }

    $crisp.push(['on', 'chat:opened', () => {
      $crisp.push(['set', 'user:email', [user?.email ?? '']])
    }])

    $crisp.push(['on', 'message:received', () => {
      $crisp.push(['do', 'chat:show'])
      $crisp.push(['do', 'chat:open'])
    }])

    return () => {
      $crisp.push(["off", 'chat:opened'])
      $crisp.push(["off", 'message:received'])
      $crisp.push(["off", 'chat:closed'])
    }
  }, [$crisp, user?.email, visible])

  useEffect(() => {
    if (!$crisp) return
    if (visible) $crisp.push(['do', 'chat:show'])
    else $crisp.push(['do', 'chat:hide'])
  }, [$crisp, visible])

  const show = useCallback(() => {
    setVisible(true)
  }, [])

  const hide = useCallback(() => {
    setVisible(false)
  }, [])

  const open = useCallback(() => {
    if (!$crisp) return
    $crisp.push(['do', 'chat:show'])
    $crisp.push(['do', 'chat:open'])
  }, [$crisp])

  const value = useMemo(() => ({
    show,
    hide,
    visible,
    open,
  }), [show, hide, visible, open])

  return (
    <CrispContext.Provider value={value}>
      {children}
    </CrispContext.Provider>
  )
}

export function useCrisp () {
  return useContext(CrispContext)
}

export function useCrispVisible (visible: boolean) {
  const { show, hide } = useCrisp()

  useEffect(() => {
    if (visible) show()
    else hide()
  }, [hide, show, visible])
}

export interface CrispProps {
  visible?: boolean
}

export function Crisp ({ visible }: CrispProps) {
  useCrispVisible(!!visible)
  return null
}

