import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `Int53` scalar type represents non-fractional signed whole numeric values. Int53 can represent values between -(2^53 - 1) and 2^53 - 1.  */
  Int53: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AddPaymentMethodInput = {
  paymentMethodId: Scalars['String'];
  setAsDefault?: InputMaybe<Scalars['Boolean']>;
};

export type Alert = {
  __typename?: 'Alert';
  filter?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['JSONObject']>;
  mode?: Maybe<AlertMode>;
  name: Scalars['String'];
  notifiers?: Maybe<Scalars['JSONObject']>;
};

export const AlertMode = {
  All: 'all',
  Everyx: 'everyx',
} as const

export type AlertMode = typeof AlertMode[keyof typeof AlertMode];
export type CreateAlertInput = {
  filter?: InputMaybe<Scalars['JSONObject']>;
  meta?: InputMaybe<Scalars['JSONObject']>;
  mode: AlertMode;
  name: Scalars['String'];
  notifiers?: InputMaybe<Scalars['JSONObject']>;
  workspaceId: Scalars['String'];
};

export type CreateWebhookInput = {
  body?: InputMaybe<Scalars['Boolean']>;
  events: Array<Scalars['String']>;
  type: WebhookType;
  url: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type CreateWorkspaceInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  planId?: InputMaybe<Scalars['String']>;
};

export type DeletePaymentMethodInput = {
  paymentMethodId: Scalars['String'];
  setAsDefault?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteUserInput = {
  reason?: InputMaybe<Scalars['String']>;
};

export type InviteUserToWorkspaceInput = {
  emails: Array<Scalars['String']>;
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  role: Scalars['Int'];
};

export type LoginAsUserInput = {
  email: Scalars['String'];
};

export type LoginUserInput = {
  allowSignup?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  skipVerification?: InputMaybe<Scalars['Boolean']>;
};

export type LoginUserWithTokenInput = {
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addPaymentMethod?: Maybe<User>;
  createAlert: Alert;
  createToken: Token;
  createWebhook?: Maybe<Webhook>;
  createWorkspace?: Maybe<Workspace>;
  deleteAlert: Alert;
  deleteMe?: Maybe<User>;
  deletePaymentMethod?: Maybe<User>;
  deleteToken: Token;
  deleteWebhook?: Maybe<Webhook>;
  deleteWorkspace?: Maybe<Workspace>;
  deleteWorkspaceUser?: Maybe<WorkspaceUser>;
  inviteUserToWorkspace?: Maybe<Workspace>;
  loginAsUser?: Maybe<UserAuth>;
  loginUser?: Maybe<UserAuth>;
  loginUserWithToken?: Maybe<UserAuth>;
  requestLoginUserEmail?: Maybe<MutationStatus>;
  requestResetPassword?: Maybe<MutationStatus>;
  requestValidateEmail?: Maybe<MutationStatus>;
  resetPassword?: Maybe<UserAuth>;
  setWorkspaceSubscription?: Maybe<Workspace>;
  updateAlert: Alert;
  updateMe?: Maybe<User>;
  updateWebhook?: Maybe<Webhook>;
  updateWorkspace?: Maybe<Workspace>;
  updateWorkspaceUser?: Maybe<WorkspaceUser>;
};


export type MutationAddPaymentMethodArgs = {
  input: AddPaymentMethodInput;
};


export type MutationCreateAlertArgs = {
  input: CreateAlertInput;
};


export type MutationCreateTokenArgs = {
  workspace?: InputMaybe<Scalars['String']>;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationDeleteAlertArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeArgs = {
  input: DeleteUserInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeleteTokenArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWebhookArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkspaceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkspaceUserArgs = {
  id: Scalars['ID'];
};


export type MutationInviteUserToWorkspaceArgs = {
  input: InviteUserToWorkspaceInput;
};


export type MutationLoginAsUserArgs = {
  input: LoginAsUserInput;
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationLoginUserWithTokenArgs = {
  input: LoginUserWithTokenInput;
};


export type MutationRequestLoginUserEmailArgs = {
  input: RequestLoginUserEmailInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationRequestValidateEmailArgs = {
  input: RequestValidateEmailInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSetWorkspaceSubscriptionArgs = {
  input: SetWorkspaceSubscriptionInput;
};


export type MutationUpdateAlertArgs = {
  input: UpdateAlertInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationUpdateWorkspaceUserArgs = {
  input: UpdateWorkspaceUserInput;
};

export type MutationStatus = {
  __typename?: 'MutationStatus';
  status: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getRequest?: Maybe<Request>;
  getUserProfile?: Maybe<UserProfile>;
  getWebhook?: Maybe<Webhook>;
  getWorkspace?: Maybe<Workspace>;
  getWorkspaceRequestCount: Scalars['Int'];
  getWorkspaceUser?: Maybe<WorkspaceUser>;
  listAlerts: Array<Alert>;
  listRequests: Array<Request>;
  listTokens: Array<Token>;
  listWebhooks: Array<Webhook>;
  listWorkspaces: Array<Workspace>;
  me?: Maybe<User>;
};


export type QueryGetRequestArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserProfileArgs = {
  id: Scalars['ID'];
};


export type QueryGetWebhookArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkspaceArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkspaceRequestCountArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkspaceUserArgs = {
  id: Scalars['ID'];
};


export type QueryListAlertsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['ID']>;
};


export type QueryListRequestsArgs = {
  filters?: InputMaybe<Scalars['JSONObject']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  workspaceId: Scalars['String'];
};


export type QueryListTokensArgs = {
  workspace?: InputMaybe<Scalars['String']>;
};


export type QueryListWebhooksArgs = {
  workspaceId: Scalars['ID'];
};

export type Request = {
  __typename?: 'Request';
  attempts?: Maybe<Scalars['Int']>;
  cron?: Maybe<Array<Array<Scalars['String']>>>;
  delay?: Maybe<Scalars['Float']>;
  delayuntil?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  env?: Maybe<Scalars['String']>;
  event?: Maybe<RequestEvent>;
  id: Scalars['String'];
  interval?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['Float']>;
  request?: Maybe<RequestRequest>;
  requires?: Maybe<Scalars['JSONObject']>;
  response?: Maybe<RequestResponse>;
  retry?: Maybe<RequestRetry>;
  source?: Maybe<RequestSource>;
  start?: Maybe<Scalars['Float']>;
  status?: Maybe<RequestStatus>;
  step?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['JSONObject']>;
  timezone?: Maybe<Scalars['String']>;
  trace?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['String']>;
  workspace: Scalars['String'];
};

export type RequestEvent = {
  __typename?: 'RequestEvent';
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type RequestLoginUserEmailInput = {
  email: Scalars['String'];
  isSignup?: InputMaybe<Scalars['Boolean']>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
};

export type RequestRequest = {
  __typename?: 'RequestRequest';
  body?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Float']>;
  hasbody?: Maybe<Scalars['Boolean']>;
  headers?: Maybe<Scalars['JSONObject']>;
  host?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSONObject']>;
  path?: Maybe<Scalars['String']>;
  scheme?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type RequestResponse = {
  __typename?: 'RequestResponse';
  body?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Float']>;
  hasbody?: Maybe<Scalars['Boolean']>;
  headers?: Maybe<Scalars['JSONObject']>;
  start?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Int']>;
  statustext?: Maybe<Scalars['String']>;
};

export type RequestRetry = {
  __typename?: 'RequestRetry';
  backoff?: Maybe<RequestRetryBackoff>;
  max: Scalars['Int'];
  time?: Maybe<Scalars['Float']>;
};

export const RequestRetryBackoff = {
  Exponential: 'EXPONENTIAL',
  Fixed: 'FIXED',
  Immediate: 'IMMEDIATE',
} as const

export type RequestRetryBackoff = typeof RequestRetryBackoff[keyof typeof RequestRetryBackoff];
export const RequestSource = {
  Request: 'REQUEST',
  Retry: 'RETRY',
  Schedule: 'SCHEDULE',
  Step: 'STEP',
  Webhook: 'WEBHOOK',
} as const

export type RequestSource = typeof RequestSource[keyof typeof RequestSource];
export const RequestStatus = {
  Active: 'ACTIVE',
  Error: 'ERROR',
  Inactive: 'INACTIVE',
  Pending: 'PENDING',
  Success: 'SUCCESS',
} as const

export type RequestStatus = typeof RequestStatus[keyof typeof RequestStatus];
export type RequestValidateEmailInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SetWorkspaceSubscriptionInput = {
  id: Scalars['ID'];
  paymentMethodId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['String']>;
};

export const Status = {
  Active: 'active',
  Deleted: 'deleted',
  Error: 'error',
  Init: 'init',
  Paused: 'paused',
  Pending: 'pending',
  Success: 'success',
  Trashed: 'trashed',
} as const

export type Status = typeof Status[keyof typeof Status];
export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  workspace?: Maybe<Workspace>;
};

export type UpdateAlertInput = {
  filter?: InputMaybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  meta?: InputMaybe<Scalars['JSONObject']>;
  mode: AlertMode;
  name?: InputMaybe<Scalars['String']>;
  notifiers?: InputMaybe<Scalars['JSONObject']>;
};

export type UpdateUserInput = {
  activeWorkspace?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  lastActive?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  prevLastActive?: InputMaybe<Scalars['Date']>;
  sidebarDocked?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateWebhookInput = {
  body?: InputMaybe<Scalars['Boolean']>;
  events: Array<Scalars['String']>;
  id: Scalars['ID'];
  type: WebhookType;
  url: Scalars['String'];
};

export type UpdateWorkspaceInput = {
  billing?: InputMaybe<Scalars['JSONObject']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkspaceUserInput = {
  activeBlockId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: InputMaybe<Scalars['Int']>;
  toggles?: InputMaybe<Scalars['JSON']>;
};

export type User = {
  __typename?: 'User';
  activeWorkspace?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  beta?: Maybe<Scalars['Boolean']>;
  billing?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Date'];
  data?: Maybe<Scalars['JSONObject']>;
  email?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActive?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  onboarded?: Maybe<Scalars['Boolean']>;
  prevLastActive?: Maybe<Scalars['Date']>;
  sidebarDocked?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<Scalars['Boolean']>;
  workspaces?: Maybe<Array<WorkspaceUser>>;
};

export type UserAuth = {
  __typename?: 'UserAuth';
  token: Scalars['String'];
  user?: Maybe<User>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type Webhook = {
  __typename?: 'Webhook';
  body?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<WorkspaceUser>;
  events: Array<Scalars['String']>;
  id: Scalars['ID'];
  secret: Scalars['String'];
  status: WebhookStatus;
  type: WebhookType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspace?: Maybe<Workspace>;
};

export const WebhookStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
} as const

export type WebhookStatus = typeof WebhookStatus[keyof typeof WebhookStatus];
export const WebhookType = {
  All: 'all',
  Failed: 'failed',
  Specific: 'specific',
} as const

export type WebhookType = typeof WebhookType[keyof typeof WebhookType];
export type Workspace = {
  __typename?: 'Workspace';
  billing?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  me?: Maybe<WorkspaceUser>;
  name?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['JSONObject']>;
  requestsCount?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['Date']>;
  users: Array<WorkspaceUser>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type WorkspaceUser = {
  __typename?: 'WorkspaceUser';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role: Scalars['Int'];
  toggles?: Maybe<Scalars['JSON']>;
  user: UserProfile;
  workspace: Workspace;
};

export type AlertFragmentFragment = { __typename?: 'Alert', id: string, name: string, mode?: AlertMode | null, notifiers?: any | null, meta?: any | null };

export type ListAlertsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type ListAlertsQuery = { __typename?: 'Query', listAlerts: Array<{ __typename?: 'Alert', id: string, name: string, mode?: AlertMode | null, notifiers?: any | null, meta?: any | null }> };

export type CreateAlertMutationVariables = Exact<{
  input: CreateAlertInput;
}>;


export type CreateAlertMutation = { __typename?: 'Mutation', createAlert: { __typename?: 'Alert', id: string, name: string, mode?: AlertMode | null, notifiers?: any | null, meta?: any | null } };

export type UpdateAlertMutationVariables = Exact<{
  input: UpdateAlertInput;
}>;


export type UpdateAlertMutation = { __typename?: 'Mutation', updateAlert: { __typename?: 'Alert', id: string, name: string, mode?: AlertMode | null, notifiers?: any | null, meta?: any | null } };

export type DeleteAlertMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAlertMutation = { __typename?: 'Mutation', deleteAlert: { __typename?: 'Alert', id: string, name: string, mode?: AlertMode | null, notifiers?: any | null, meta?: any | null } };

export type RequestListFragmentFragment = { __typename?: 'Request', id: string, workspace: string, key?: string | null, trace?: string | null, status?: RequestStatus | null, source?: RequestSource | null, start?: number | null, end?: number | null, delay?: number | null, delayuntil?: number | null, interval?: number | null, cron?: Array<Array<string>> | null, attempts?: number | null, timezone?: string | null, received?: number | null, step?: string | null, steps?: any | null, requires?: any | null, env?: string | null, webhook?: string | null, event?: { __typename?: 'RequestEvent', id?: string | null, event?: string | null } | null, retry?: { __typename?: 'RequestRetry', max: number, backoff?: RequestRetryBackoff | null, time?: number | null } | null, request?: { __typename?: 'RequestRequest', method?: string | null, url?: string | null } | null, response?: { __typename?: 'RequestResponse', status?: number | null } | null };

export type RequestDetailFragmentFragment = { __typename?: 'Request', message?: string | null, duration?: number | null, id: string, workspace: string, key?: string | null, trace?: string | null, status?: RequestStatus | null, source?: RequestSource | null, start?: number | null, end?: number | null, delay?: number | null, delayuntil?: number | null, interval?: number | null, cron?: Array<Array<string>> | null, attempts?: number | null, timezone?: string | null, received?: number | null, step?: string | null, steps?: any | null, requires?: any | null, env?: string | null, webhook?: string | null, request?: { __typename?: 'RequestRequest', method?: string | null, url?: string | null, headers?: any | null, scheme?: string | null, host?: string | null, path?: string | null, params?: any | null, hasbody?: boolean | null, start?: number | null, end?: number | null } | null, response?: { __typename?: 'RequestResponse', status?: number | null, statustext?: string | null, headers?: any | null, hasbody?: boolean | null, start?: number | null, end?: number | null } | null, event?: { __typename?: 'RequestEvent', id?: string | null, event?: string | null } | null, retry?: { __typename?: 'RequestRetry', max: number, backoff?: RequestRetryBackoff | null, time?: number | null } | null };

export type GetRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRequestQuery = { __typename?: 'Query', getRequest?: { __typename?: 'Request', message?: string | null, duration?: number | null, id: string, workspace: string, key?: string | null, trace?: string | null, status?: RequestStatus | null, source?: RequestSource | null, start?: number | null, end?: number | null, delay?: number | null, delayuntil?: number | null, interval?: number | null, cron?: Array<Array<string>> | null, attempts?: number | null, timezone?: string | null, received?: number | null, step?: string | null, steps?: any | null, requires?: any | null, env?: string | null, webhook?: string | null, request?: { __typename?: 'RequestRequest', method?: string | null, url?: string | null, headers?: any | null, scheme?: string | null, host?: string | null, path?: string | null, params?: any | null, hasbody?: boolean | null, start?: number | null, end?: number | null } | null, response?: { __typename?: 'RequestResponse', status?: number | null, statustext?: string | null, headers?: any | null, hasbody?: boolean | null, start?: number | null, end?: number | null } | null, event?: { __typename?: 'RequestEvent', id?: string | null, event?: string | null } | null, retry?: { __typename?: 'RequestRetry', max: number, backoff?: RequestRetryBackoff | null, time?: number | null } | null } | null };

export type ListRequestsQueryVariables = Exact<{
  workspace: Scalars['String'];
  filters?: InputMaybe<Scalars['JSONObject']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type ListRequestsQuery = { __typename?: 'Query', listRequests: Array<{ __typename?: 'Request', id: string, workspace: string, key?: string | null, trace?: string | null, status?: RequestStatus | null, source?: RequestSource | null, start?: number | null, end?: number | null, delay?: number | null, delayuntil?: number | null, interval?: number | null, cron?: Array<Array<string>> | null, attempts?: number | null, timezone?: string | null, received?: number | null, step?: string | null, steps?: any | null, requires?: any | null, env?: string | null, webhook?: string | null, event?: { __typename?: 'RequestEvent', id?: string | null, event?: string | null } | null, retry?: { __typename?: 'RequestRetry', max: number, backoff?: RequestRetryBackoff | null, time?: number | null } | null, request?: { __typename?: 'RequestRequest', method?: string | null, url?: string | null } | null, response?: { __typename?: 'RequestResponse', status?: number | null } | null }> };

export type WebhookFragmentFragment = { __typename?: 'Webhook', id: string, url: string, type: WebhookType, status: WebhookStatus, body?: boolean | null, secret: string, events: Array<string>, createdAt?: any | null };

export type GetWebhookQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWebhookQuery = { __typename?: 'Query', getWebhook?: { __typename?: 'Webhook', id: string, url: string, type: WebhookType, status: WebhookStatus, body?: boolean | null, secret: string, events: Array<string>, createdAt?: any | null } | null };

export type ListWebhooksQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type ListWebhooksQuery = { __typename?: 'Query', listWebhooks: Array<{ __typename?: 'Webhook', id: string, url: string, type: WebhookType, status: WebhookStatus, body?: boolean | null, secret: string, events: Array<string>, createdAt?: any | null }> };

export type CreateWebhookMutationVariables = Exact<{
  input: CreateWebhookInput;
}>;


export type CreateWebhookMutation = { __typename?: 'Mutation', createWebhook?: { __typename?: 'Webhook', id: string, url: string, type: WebhookType, status: WebhookStatus, body?: boolean | null, secret: string, events: Array<string>, createdAt?: any | null } | null };

export type UpdateWebhookMutationVariables = Exact<{
  input: UpdateWebhookInput;
}>;


export type UpdateWebhookMutation = { __typename?: 'Mutation', updateWebhook?: { __typename?: 'Webhook', id: string, url: string, type: WebhookType, status: WebhookStatus, body?: boolean | null, secret: string, events: Array<string>, createdAt?: any | null } | null };

export type DeleteWebhookMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWebhookMutation = { __typename?: 'Mutation', deleteWebhook?: { __typename?: 'Webhook', id: string, url: string, type: WebhookType, status: WebhookStatus, body?: boolean | null, secret: string, events: Array<string>, createdAt?: any | null } | null };

export type TokenFragmentFragment = { __typename?: 'Token', id: string };

export type ListTokensQueryVariables = Exact<{
  workspace: Scalars['String'];
}>;


export type ListTokensQuery = { __typename?: 'Query', listTokens: Array<{ __typename?: 'Token', id: string }> };

export type CreateTokenMutationVariables = Exact<{
  workspace: Scalars['String'];
}>;


export type CreateTokenMutation = { __typename?: 'Mutation', createToken: { __typename?: 'Token', id: string } };

export type DeleteTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTokenMutation = { __typename?: 'Mutation', deleteToken: { __typename?: 'Token', id: string } };

export type MeFieldsFragment = { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null };

export type UserProfileFragmentFragment = { __typename?: 'UserProfile', id: string, name?: string | null, icon?: string | null, email?: string | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUserProfile?: { __typename?: 'UserProfile', id: string, name?: string | null, icon?: string | null, email?: string | null } | null };

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'UserAuth', token: string, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null } | null };

export type LoginAsUserMutationVariables = Exact<{
  input: LoginAsUserInput;
}>;


export type LoginAsUserMutation = { __typename?: 'Mutation', loginAsUser?: { __typename?: 'UserAuth', token: string, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null } | null };

export type LoginUserWithTokenMutationVariables = Exact<{
  input: LoginUserWithTokenInput;
}>;


export type LoginUserWithTokenMutation = { __typename?: 'Mutation', loginUserWithToken?: { __typename?: 'UserAuth', token: string, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null } | null };

export type RequestResetPasswordMutationVariables = Exact<{
  input: RequestResetPasswordInput;
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword?: { __typename?: 'MutationStatus', status: string } | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'UserAuth', token: string, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null } | null };

export type RequestLoginUserEmailMutationVariables = Exact<{
  input: RequestLoginUserEmailInput;
}>;


export type RequestLoginUserEmailMutation = { __typename?: 'Mutation', requestLoginUserEmail?: { __typename?: 'MutationStatus', status: string } | null };

export type RequestValidateEmailMutationVariables = Exact<{
  input: RequestValidateEmailInput;
}>;


export type RequestValidateEmailMutation = { __typename?: 'Mutation', requestValidateEmail?: { __typename?: 'MutationStatus', status: string } | null };

export type UpdateMeMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null };

export type DeleteMeMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteMeMutation = { __typename?: 'Mutation', deleteMe?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null };

export type AddPaymentMethodMutationVariables = Exact<{
  input: AddPaymentMethodInput;
}>;


export type AddPaymentMethodMutation = { __typename?: 'Mutation', addPaymentMethod?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, icon?: string | null, data?: any | null, beta?: boolean | null, admin?: boolean | null, verified?: boolean | null, sidebarDocked?: boolean | null, activeWorkspace?: string | null, lastActive?: any | null, prevLastActive?: any | null, onboarded?: boolean | null, billing?: { [key: string]: any } | null, workspaces?: Array<{ __typename?: 'WorkspaceUser', id: string, role: number, workspace: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } }> | null } | null };

export type WorkspaceListFragmentFragment = { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null };

export type WorkspaceDetailFragmentFragment = { __typename?: 'Workspace', id: string, name?: string | null, icon?: string | null, role?: number | null, billing?: any | null, plan?: any | null, users: Array<{ __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } }>, me?: { __typename?: 'WorkspaceUser', id: string, role: number } | null };

export type WorkspaceUserFragmentFragment = { __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } };

export type GetWorkspaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkspaceQuery = { __typename?: 'Query', getWorkspace?: { __typename?: 'Workspace', id: string, name?: string | null, icon?: string | null, role?: number | null, billing?: any | null, plan?: any | null, users: Array<{ __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } }>, me?: { __typename?: 'WorkspaceUser', id: string, role: number } | null } | null };

export type GetWorkspaceWithUsersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkspaceWithUsersQuery = { __typename?: 'Query', getWorkspace?: { __typename?: 'Workspace', id: string, name?: string | null, icon?: string | null, role?: number | null, billing?: any | null, plan?: any | null, users: Array<{ __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } }>, me?: { __typename?: 'WorkspaceUser', id: string, role: number } | null } | null };

export type GetWorkspaceUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkspaceUserQuery = { __typename?: 'Query', getWorkspaceUser?: { __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } } | null };

export type GetWorkspaceRequestCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkspaceRequestCountQuery = { __typename?: 'Query', getWorkspaceRequestCount: number };

export type ListWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkspacesQuery = { __typename?: 'Query', listWorkspaces: Array<{ __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null }> };

export type ListWorkspacesWithUserCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkspacesWithUserCountQuery = { __typename?: 'Query', listWorkspaces: Array<{ __typename?: 'Workspace', usersCount?: number | null, id: string, name?: string | null, role?: number | null, icon?: string | null }> };

export type CreateWorkspaceMutationVariables = Exact<{
  input: CreateWorkspaceInput;
}>;


export type CreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace?: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } | null };

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput;
}>;


export type UpdateWorkspaceMutation = { __typename?: 'Mutation', updateWorkspace?: { __typename?: 'Workspace', id: string, name?: string | null, icon?: string | null, role?: number | null, billing?: any | null, plan?: any | null, users: Array<{ __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } }>, me?: { __typename?: 'WorkspaceUser', id: string, role: number } | null } | null };

export type DeleteWorkspaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkspaceMutation = { __typename?: 'Mutation', deleteWorkspace?: { __typename?: 'Workspace', id: string, name?: string | null, role?: number | null, icon?: string | null } | null };

export type UpdateWorkspaceUserMutationVariables = Exact<{
  input: UpdateWorkspaceUserInput;
}>;


export type UpdateWorkspaceUserMutation = { __typename?: 'Mutation', updateWorkspaceUser?: { __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } } | null };

export type DeleteWorkspaceUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkspaceUserMutation = { __typename?: 'Mutation', deleteWorkspaceUser?: { __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } } | null };

export type InviteUserToWorkspaceMutationVariables = Exact<{
  input: InviteUserToWorkspaceInput;
}>;


export type InviteUserToWorkspaceMutation = { __typename?: 'Mutation', inviteUserToWorkspace?: { __typename?: 'Workspace', id: string, name?: string | null, icon?: string | null, role?: number | null, billing?: any | null, plan?: any | null, users: Array<{ __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } }>, me?: { __typename?: 'WorkspaceUser', id: string, role: number } | null } | null };

export type SetWorkspaceSubscriptionMutationVariables = Exact<{
  input: SetWorkspaceSubscriptionInput;
}>;


export type SetWorkspaceSubscriptionMutation = { __typename?: 'Mutation', setWorkspaceSubscription?: { __typename?: 'Workspace', id: string, name?: string | null, icon?: string | null, role?: number | null, billing?: any | null, plan?: any | null, users: Array<{ __typename?: 'WorkspaceUser', id: string, name?: string | null, icon?: string | null, role: number, user: { __typename?: 'UserProfile', id: string, email?: string | null } }>, me?: { __typename?: 'WorkspaceUser', id: string, role: number } | null } | null };

export const AlertFragmentFragmentDoc = gql`
    fragment AlertFragment on Alert {
  id
  name
  mode
  notifiers
  meta
}
    `
export const RequestListFragmentFragmentDoc = gql`
    fragment RequestListFragment on Request {
  id
  workspace
  key
  trace
  status
  source
  start
  end
  delay
  delayuntil
  interval
  cron
  attempts
  timezone
  received
  step
  steps
  requires
  env
  webhook
  event {
    id
    event
  }
  retry {
    max
    backoff
    time
  }
  request {
    method
    url
  }
  response {
    status
  }
}
    `
export const RequestDetailFragmentFragmentDoc = gql`
    fragment RequestDetailFragment on Request {
  ...RequestListFragment
  message
  duration
  request {
    method
    url
    headers
    scheme
    host
    path
    params
    hasbody
    start
    end
  }
  response {
    status
    statustext
    headers
    hasbody
    start
    end
  }
}
    ${RequestListFragmentFragmentDoc}`
export const WebhookFragmentFragmentDoc = gql`
    fragment WebhookFragment on Webhook {
  id
  url
  type
  status
  body
  secret
  events
  createdAt
}
    `
export const TokenFragmentFragmentDoc = gql`
    fragment TokenFragment on Token {
  id
}
    `
export const WorkspaceListFragmentFragmentDoc = gql`
    fragment WorkspaceListFragment on Workspace {
  id
  name
  role
  icon
}
    `
export const MeFieldsFragmentDoc = gql`
    fragment MeFields on User {
  id
  name
  email
  icon
  data
  beta
  admin
  verified
  sidebarDocked
  activeWorkspace
  lastActive
  prevLastActive
  onboarded
  billing
  workspaces {
    id
    role
    workspace {
      ...WorkspaceListFragment
    }
  }
}
    ${WorkspaceListFragmentFragmentDoc}`
export const UserProfileFragmentFragmentDoc = gql`
    fragment UserProfileFragment on UserProfile {
  id
  name
  icon
  email
}
    `
export const WorkspaceUserFragmentFragmentDoc = gql`
    fragment WorkspaceUserFragment on WorkspaceUser {
  id
  name
  icon
  role
  user {
    id
    email
  }
}
    `
export const WorkspaceDetailFragmentFragmentDoc = gql`
    fragment WorkspaceDetailFragment on Workspace {
  id
  name
  icon
  role
  billing
  plan
  users {
    ...WorkspaceUserFragment
  }
  me {
    id
    role
  }
}
    ${WorkspaceUserFragmentFragmentDoc}`
export const ListAlertsDocument = gql`
    query ListAlerts($workspaceId: ID!) {
  listAlerts(workspaceId: $workspaceId) {
    ...AlertFragment
  }
}
    ${AlertFragmentFragmentDoc}`

/**
 * __useListAlertsQuery__
 *
 * To run a query within a React component, call `useListAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlertsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useListAlertsQuery (baseOptions: Apollo.QueryHookOptions<ListAlertsQuery, ListAlertsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAlertsQuery, ListAlertsQueryVariables>(ListAlertsDocument, options)
}
export function useListAlertsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<ListAlertsQuery, ListAlertsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAlertsQuery, ListAlertsQueryVariables>(ListAlertsDocument, options)
}
export type ListAlertsQueryHookResult = ReturnType<typeof useListAlertsQuery>;
export type ListAlertsLazyQueryHookResult = ReturnType<typeof useListAlertsLazyQuery>;
export type ListAlertsQueryResult = Apollo.QueryResult<ListAlertsQuery, ListAlertsQueryVariables>;
export const CreateAlertDocument = gql`
    mutation CreateAlert($input: CreateAlertInput!) {
  createAlert(input: $input) {
    ...AlertFragment
  }
}
    ${AlertFragmentFragmentDoc}`
export type CreateAlertMutationFn = Apollo.MutationFunction<CreateAlertMutation, CreateAlertMutationVariables>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlertMutation (baseOptions?: Apollo.MutationHookOptions<CreateAlertMutation, CreateAlertMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAlertMutation, CreateAlertMutationVariables>(CreateAlertDocument, options)
}
export type CreateAlertMutationHookResult = ReturnType<typeof useCreateAlertMutation>;
export type CreateAlertMutationResult = Apollo.MutationResult<CreateAlertMutation>;
export type CreateAlertMutationOptions = Apollo.BaseMutationOptions<CreateAlertMutation, CreateAlertMutationVariables>;
export const UpdateAlertDocument = gql`
    mutation UpdateAlert($input: UpdateAlertInput!) {
  updateAlert(input: $input) {
    ...AlertFragment
  }
}
    ${AlertFragmentFragmentDoc}`
export type UpdateAlertMutationFn = Apollo.MutationFunction<UpdateAlertMutation, UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertMutation (baseOptions?: Apollo.MutationHookOptions<UpdateAlertMutation, UpdateAlertMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(UpdateAlertDocument, options)
}
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = Apollo.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = Apollo.BaseMutationOptions<UpdateAlertMutation, UpdateAlertMutationVariables>;
export const DeleteAlertDocument = gql`
    mutation DeleteAlert($id: ID!) {
  deleteAlert(id: $id) {
    ...AlertFragment
  }
}
    ${AlertFragmentFragmentDoc}`
export type DeleteAlertMutationFn = Apollo.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertMutation (baseOptions?: Apollo.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, options)
}
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = Apollo.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = Apollo.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;
export const GetRequestDocument = gql`
    query GetRequest($id: ID!) {
  getRequest(id: $id) {
    ...RequestDetailFragment
  }
}
    ${RequestDetailFragmentFragmentDoc}`

/**
 * __useGetRequestQuery__
 *
 * To run a query within a React component, call `useGetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestQuery (baseOptions: Apollo.QueryHookOptions<GetRequestQuery, GetRequestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequestQuery, GetRequestQueryVariables>(GetRequestDocument, options)
}
export function useGetRequestLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetRequestQuery, GetRequestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequestQuery, GetRequestQueryVariables>(GetRequestDocument, options)
}
export type GetRequestQueryHookResult = ReturnType<typeof useGetRequestQuery>;
export type GetRequestLazyQueryHookResult = ReturnType<typeof useGetRequestLazyQuery>;
export type GetRequestQueryResult = Apollo.QueryResult<GetRequestQuery, GetRequestQueryVariables>;
export const ListRequestsDocument = gql`
    query ListRequests($workspace: String!, $filters: JSONObject, $limit: Int, $page: Int) {
  listRequests(
    workspaceId: $workspace
    filters: $filters
    limit: $limit
    page: $page
  ) {
    ...RequestListFragment
  }
}
    ${RequestListFragmentFragmentDoc}`

/**
 * __useListRequestsQuery__
 *
 * To run a query within a React component, call `useListRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRequestsQuery({
 *   variables: {
 *      workspace: // value for 'workspace'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListRequestsQuery (baseOptions: Apollo.QueryHookOptions<ListRequestsQuery, ListRequestsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListRequestsQuery, ListRequestsQueryVariables>(ListRequestsDocument, options)
}
export function useListRequestsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<ListRequestsQuery, ListRequestsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListRequestsQuery, ListRequestsQueryVariables>(ListRequestsDocument, options)
}
export type ListRequestsQueryHookResult = ReturnType<typeof useListRequestsQuery>;
export type ListRequestsLazyQueryHookResult = ReturnType<typeof useListRequestsLazyQuery>;
export type ListRequestsQueryResult = Apollo.QueryResult<ListRequestsQuery, ListRequestsQueryVariables>;
export const GetWebhookDocument = gql`
    query GetWebhook($id: ID!) {
  getWebhook(id: $id) {
    ...WebhookFragment
  }
}
    ${WebhookFragmentFragmentDoc}`

/**
 * __useGetWebhookQuery__
 *
 * To run a query within a React component, call `useGetWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWebhookQuery (baseOptions: Apollo.QueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, options)
}
export function useGetWebhookLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, options)
}
export type GetWebhookQueryHookResult = ReturnType<typeof useGetWebhookQuery>;
export type GetWebhookLazyQueryHookResult = ReturnType<typeof useGetWebhookLazyQuery>;
export type GetWebhookQueryResult = Apollo.QueryResult<GetWebhookQuery, GetWebhookQueryVariables>;
export const ListWebhooksDocument = gql`
    query ListWebhooks($workspaceId: ID!) {
  listWebhooks(workspaceId: $workspaceId) {
    ...WebhookFragment
  }
}
    ${WebhookFragmentFragmentDoc}`

/**
 * __useListWebhooksQuery__
 *
 * To run a query within a React component, call `useListWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWebhooksQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useListWebhooksQuery (baseOptions: Apollo.QueryHookOptions<ListWebhooksQuery, ListWebhooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListWebhooksQuery, ListWebhooksQueryVariables>(ListWebhooksDocument, options)
}
export function useListWebhooksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<ListWebhooksQuery, ListWebhooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListWebhooksQuery, ListWebhooksQueryVariables>(ListWebhooksDocument, options)
}
export type ListWebhooksQueryHookResult = ReturnType<typeof useListWebhooksQuery>;
export type ListWebhooksLazyQueryHookResult = ReturnType<typeof useListWebhooksLazyQuery>;
export type ListWebhooksQueryResult = Apollo.QueryResult<ListWebhooksQuery, ListWebhooksQueryVariables>;
export const CreateWebhookDocument = gql`
    mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    ...WebhookFragment
  }
}
    ${WebhookFragmentFragmentDoc}`
export type CreateWebhookMutationFn = Apollo.MutationFunction<CreateWebhookMutation, CreateWebhookMutationVariables>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation (baseOptions?: Apollo.MutationHookOptions<CreateWebhookMutation, CreateWebhookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateWebhookMutation, CreateWebhookMutationVariables>(CreateWebhookDocument, options)
}
export type CreateWebhookMutationHookResult = ReturnType<typeof useCreateWebhookMutation>;
export type CreateWebhookMutationResult = Apollo.MutationResult<CreateWebhookMutation>;
export type CreateWebhookMutationOptions = Apollo.BaseMutationOptions<CreateWebhookMutation, CreateWebhookMutationVariables>;
export const UpdateWebhookDocument = gql`
    mutation UpdateWebhook($input: UpdateWebhookInput!) {
  updateWebhook(input: $input) {
    ...WebhookFragment
  }
}
    ${WebhookFragmentFragmentDoc}`
export type UpdateWebhookMutationFn = Apollo.MutationFunction<UpdateWebhookMutation, UpdateWebhookMutationVariables>;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookMutation (baseOptions?: Apollo.MutationHookOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateWebhookMutation, UpdateWebhookMutationVariables>(UpdateWebhookDocument, options)
}
export type UpdateWebhookMutationHookResult = ReturnType<typeof useUpdateWebhookMutation>;
export type UpdateWebhookMutationResult = Apollo.MutationResult<UpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>;
export const DeleteWebhookDocument = gql`
    mutation DeleteWebhook($id: ID!) {
  deleteWebhook(id: $id) {
    ...WebhookFragment
  }
}
    ${WebhookFragmentFragmentDoc}`
export type DeleteWebhookMutationFn = Apollo.MutationFunction<DeleteWebhookMutation, DeleteWebhookMutationVariables>;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebhookMutation (baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(DeleteWebhookDocument, options)
}
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
export type DeleteWebhookMutationResult = Apollo.MutationResult<DeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>;
export const ListTokensDocument = gql`
    query ListTokens($workspace: String!) {
  listTokens(workspace: $workspace) {
    ...TokenFragment
  }
}
    ${TokenFragmentFragmentDoc}`

/**
 * __useListTokensQuery__
 *
 * To run a query within a React component, call `useListTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTokensQuery({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useListTokensQuery (baseOptions: Apollo.QueryHookOptions<ListTokensQuery, ListTokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTokensQuery, ListTokensQueryVariables>(ListTokensDocument, options)
}
export function useListTokensLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<ListTokensQuery, ListTokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTokensQuery, ListTokensQueryVariables>(ListTokensDocument, options)
}
export type ListTokensQueryHookResult = ReturnType<typeof useListTokensQuery>;
export type ListTokensLazyQueryHookResult = ReturnType<typeof useListTokensLazyQuery>;
export type ListTokensQueryResult = Apollo.QueryResult<ListTokensQuery, ListTokensQueryVariables>;
export const CreateTokenDocument = gql`
    mutation CreateToken($workspace: String!) {
  createToken(workspace: $workspace) {
    ...TokenFragment
  }
}
    ${TokenFragmentFragmentDoc}`
export type CreateTokenMutationFn = Apollo.MutationFunction<CreateTokenMutation, CreateTokenMutationVariables>;

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useCreateTokenMutation (baseOptions?: Apollo.MutationHookOptions<CreateTokenMutation, CreateTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(CreateTokenDocument, options)
}
export type CreateTokenMutationHookResult = ReturnType<typeof useCreateTokenMutation>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<CreateTokenMutation, CreateTokenMutationVariables>;
export const DeleteTokenDocument = gql`
    mutation DeleteToken($id: ID!) {
  deleteToken(id: $id) {
    ...TokenFragment
  }
}
    ${TokenFragmentFragmentDoc}`
export type DeleteTokenMutationFn = Apollo.MutationFunction<DeleteTokenMutation, DeleteTokenMutationVariables>;

/**
 * __useDeleteTokenMutation__
 *
 * To run a mutation, you first call `useDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokenMutation, { data, loading, error }] = useDeleteTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTokenMutation (baseOptions?: Apollo.MutationHookOptions<DeleteTokenMutation, DeleteTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTokenMutation, DeleteTokenMutationVariables>(DeleteTokenDocument, options)
}
export type DeleteTokenMutationHookResult = ReturnType<typeof useDeleteTokenMutation>;
export type DeleteTokenMutationResult = Apollo.MutationResult<DeleteTokenMutation>;
export type DeleteTokenMutationOptions = Apollo.BaseMutationOptions<DeleteTokenMutation, DeleteTokenMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...MeFields
  }
}
    ${MeFieldsFragmentDoc}`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery (baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  getUserProfile(id: $id) {
    ...UserProfileFragment
  }
}
    ${UserProfileFragmentFragmentDoc}`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery (baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options)
}
export function useGetUserLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options)
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($input: LoginUserInput!) {
  loginUser(input: $input) {
    user {
      ...MeFields
    }
    token
  }
}
    ${MeFieldsFragmentDoc}`
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation (baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options)
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LoginAsUserDocument = gql`
    mutation LoginAsUser($input: LoginAsUserInput!) {
  loginAsUser(input: $input) {
    user {
      ...MeFields
    }
    token
  }
}
    ${MeFieldsFragmentDoc}`
export type LoginAsUserMutationFn = Apollo.MutationFunction<LoginAsUserMutation, LoginAsUserMutationVariables>;

/**
 * __useLoginAsUserMutation__
 *
 * To run a mutation, you first call `useLoginAsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAsUserMutation, { data, loading, error }] = useLoginAsUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginAsUserMutation (baseOptions?: Apollo.MutationHookOptions<LoginAsUserMutation, LoginAsUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginAsUserMutation, LoginAsUserMutationVariables>(LoginAsUserDocument, options)
}
export type LoginAsUserMutationHookResult = ReturnType<typeof useLoginAsUserMutation>;
export type LoginAsUserMutationResult = Apollo.MutationResult<LoginAsUserMutation>;
export type LoginAsUserMutationOptions = Apollo.BaseMutationOptions<LoginAsUserMutation, LoginAsUserMutationVariables>;
export const LoginUserWithTokenDocument = gql`
    mutation LoginUserWithToken($input: LoginUserWithTokenInput!) {
  loginUserWithToken(input: $input) {
    user {
      ...MeFields
    }
    token
  }
}
    ${MeFieldsFragmentDoc}`
export type LoginUserWithTokenMutationFn = Apollo.MutationFunction<LoginUserWithTokenMutation, LoginUserWithTokenMutationVariables>;

/**
 * __useLoginUserWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginUserWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserWithTokenMutation, { data, loading, error }] = useLoginUserWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserWithTokenMutation (baseOptions?: Apollo.MutationHookOptions<LoginUserWithTokenMutation, LoginUserWithTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginUserWithTokenMutation, LoginUserWithTokenMutationVariables>(LoginUserWithTokenDocument, options)
}
export type LoginUserWithTokenMutationHookResult = ReturnType<typeof useLoginUserWithTokenMutation>;
export type LoginUserWithTokenMutationResult = Apollo.MutationResult<LoginUserWithTokenMutation>;
export type LoginUserWithTokenMutationOptions = Apollo.BaseMutationOptions<LoginUserWithTokenMutation, LoginUserWithTokenMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($input: RequestResetPasswordInput!) {
  requestResetPassword(input: $input) {
    status
  }
}
    `
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordMutation (baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options)
}
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    user {
      ...MeFields
    }
    token
  }
}
    ${MeFieldsFragmentDoc}`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation (baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RequestLoginUserEmailDocument = gql`
    mutation RequestLoginUserEmail($input: RequestLoginUserEmailInput!) {
  requestLoginUserEmail(input: $input) {
    status
  }
}
    `
export type RequestLoginUserEmailMutationFn = Apollo.MutationFunction<RequestLoginUserEmailMutation, RequestLoginUserEmailMutationVariables>;

/**
 * __useRequestLoginUserEmailMutation__
 *
 * To run a mutation, you first call `useRequestLoginUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLoginUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLoginUserEmailMutation, { data, loading, error }] = useRequestLoginUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestLoginUserEmailMutation (baseOptions?: Apollo.MutationHookOptions<RequestLoginUserEmailMutation, RequestLoginUserEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestLoginUserEmailMutation, RequestLoginUserEmailMutationVariables>(RequestLoginUserEmailDocument, options)
}
export type RequestLoginUserEmailMutationHookResult = ReturnType<typeof useRequestLoginUserEmailMutation>;
export type RequestLoginUserEmailMutationResult = Apollo.MutationResult<RequestLoginUserEmailMutation>;
export type RequestLoginUserEmailMutationOptions = Apollo.BaseMutationOptions<RequestLoginUserEmailMutation, RequestLoginUserEmailMutationVariables>;
export const RequestValidateEmailDocument = gql`
    mutation RequestValidateEmail($input: RequestValidateEmailInput!) {
  requestValidateEmail(input: $input) {
    status
  }
}
    `
export type RequestValidateEmailMutationFn = Apollo.MutationFunction<RequestValidateEmailMutation, RequestValidateEmailMutationVariables>;

/**
 * __useRequestValidateEmailMutation__
 *
 * To run a mutation, you first call `useRequestValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestValidateEmailMutation, { data, loading, error }] = useRequestValidateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestValidateEmailMutation (baseOptions?: Apollo.MutationHookOptions<RequestValidateEmailMutation, RequestValidateEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestValidateEmailMutation, RequestValidateEmailMutationVariables>(RequestValidateEmailDocument, options)
}
export type RequestValidateEmailMutationHookResult = ReturnType<typeof useRequestValidateEmailMutation>;
export type RequestValidateEmailMutationResult = Apollo.MutationResult<RequestValidateEmailMutation>;
export type RequestValidateEmailMutationOptions = Apollo.BaseMutationOptions<RequestValidateEmailMutation, RequestValidateEmailMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($input: UpdateUserInput!) {
  updateMe(input: $input) {
    ...MeFields
  }
}
    ${MeFieldsFragmentDoc}`
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation (baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options)
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const DeleteMeDocument = gql`
    mutation DeleteMe($input: DeleteUserInput!) {
  deleteMe(input: $input) {
    ...MeFields
  }
}
    ${MeFieldsFragmentDoc}`
export type DeleteMeMutationFn = Apollo.MutationFunction<DeleteMeMutation, DeleteMeMutationVariables>;

/**
 * __useDeleteMeMutation__
 *
 * To run a mutation, you first call `useDeleteMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeMutation, { data, loading, error }] = useDeleteMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMeMutation (baseOptions?: Apollo.MutationHookOptions<DeleteMeMutation, DeleteMeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteMeMutation, DeleteMeMutationVariables>(DeleteMeDocument, options)
}
export type DeleteMeMutationHookResult = ReturnType<typeof useDeleteMeMutation>;
export type DeleteMeMutationResult = Apollo.MutationResult<DeleteMeMutation>;
export type DeleteMeMutationOptions = Apollo.BaseMutationOptions<DeleteMeMutation, DeleteMeMutationVariables>;
export const AddPaymentMethodDocument = gql`
    mutation AddPaymentMethod($input: AddPaymentMethodInput!) {
  addPaymentMethod(input: $input) {
    ...MeFields
  }
}
    ${MeFieldsFragmentDoc}`
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutation (baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(AddPaymentMethodDocument, options)
}
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;
export const GetWorkspaceDocument = gql`
    query GetWorkspace($id: ID!) {
  getWorkspace(id: $id) {
    ...WorkspaceDetailFragment
  }
}
    ${WorkspaceDetailFragmentFragmentDoc}`

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery (baseOptions: Apollo.QueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options)
}
export function useGetWorkspaceLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options)
}
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<GetWorkspaceQuery, GetWorkspaceQueryVariables>;
export const GetWorkspaceWithUsersDocument = gql`
    query GetWorkspaceWithUsers($id: ID!) {
  getWorkspace(id: $id) {
    ...WorkspaceDetailFragment
  }
}
    ${WorkspaceDetailFragmentFragmentDoc}`

/**
 * __useGetWorkspaceWithUsersQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceWithUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceWithUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceWithUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceWithUsersQuery (baseOptions: Apollo.QueryHookOptions<GetWorkspaceWithUsersQuery, GetWorkspaceWithUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkspaceWithUsersQuery, GetWorkspaceWithUsersQueryVariables>(GetWorkspaceWithUsersDocument, options)
}
export function useGetWorkspaceWithUsersLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceWithUsersQuery, GetWorkspaceWithUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWorkspaceWithUsersQuery, GetWorkspaceWithUsersQueryVariables>(GetWorkspaceWithUsersDocument, options)
}
export type GetWorkspaceWithUsersQueryHookResult = ReturnType<typeof useGetWorkspaceWithUsersQuery>;
export type GetWorkspaceWithUsersLazyQueryHookResult = ReturnType<typeof useGetWorkspaceWithUsersLazyQuery>;
export type GetWorkspaceWithUsersQueryResult = Apollo.QueryResult<GetWorkspaceWithUsersQuery, GetWorkspaceWithUsersQueryVariables>;
export const GetWorkspaceUserDocument = gql`
    query GetWorkspaceUser($id: ID!) {
  getWorkspaceUser(id: $id) {
    ...WorkspaceUserFragment
  }
}
    ${WorkspaceUserFragmentFragmentDoc}`

/**
 * __useGetWorkspaceUserQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceUserQuery (baseOptions: Apollo.QueryHookOptions<GetWorkspaceUserQuery, GetWorkspaceUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkspaceUserQuery, GetWorkspaceUserQueryVariables>(GetWorkspaceUserDocument, options)
}
export function useGetWorkspaceUserLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceUserQuery, GetWorkspaceUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWorkspaceUserQuery, GetWorkspaceUserQueryVariables>(GetWorkspaceUserDocument, options)
}
export type GetWorkspaceUserQueryHookResult = ReturnType<typeof useGetWorkspaceUserQuery>;
export type GetWorkspaceUserLazyQueryHookResult = ReturnType<typeof useGetWorkspaceUserLazyQuery>;
export type GetWorkspaceUserQueryResult = Apollo.QueryResult<GetWorkspaceUserQuery, GetWorkspaceUserQueryVariables>;
export const GetWorkspaceRequestCountDocument = gql`
    query GetWorkspaceRequestCount($id: ID!) {
  getWorkspaceRequestCount(id: $id)
}
    `

/**
 * __useGetWorkspaceRequestCountQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceRequestCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceRequestCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceRequestCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceRequestCountQuery (baseOptions: Apollo.QueryHookOptions<GetWorkspaceRequestCountQuery, GetWorkspaceRequestCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkspaceRequestCountQuery, GetWorkspaceRequestCountQueryVariables>(GetWorkspaceRequestCountDocument, options)
}
export function useGetWorkspaceRequestCountLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceRequestCountQuery, GetWorkspaceRequestCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWorkspaceRequestCountQuery, GetWorkspaceRequestCountQueryVariables>(GetWorkspaceRequestCountDocument, options)
}
export type GetWorkspaceRequestCountQueryHookResult = ReturnType<typeof useGetWorkspaceRequestCountQuery>;
export type GetWorkspaceRequestCountLazyQueryHookResult = ReturnType<typeof useGetWorkspaceRequestCountLazyQuery>;
export type GetWorkspaceRequestCountQueryResult = Apollo.QueryResult<GetWorkspaceRequestCountQuery, GetWorkspaceRequestCountQueryVariables>;
export const ListWorkspacesDocument = gql`
    query ListWorkspaces {
  listWorkspaces {
    ...WorkspaceListFragment
  }
}
    ${WorkspaceListFragmentFragmentDoc}`

/**
 * __useListWorkspacesQuery__
 *
 * To run a query within a React component, call `useListWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkspacesQuery (baseOptions?: Apollo.QueryHookOptions<ListWorkspacesQuery, ListWorkspacesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListWorkspacesQuery, ListWorkspacesQueryVariables>(ListWorkspacesDocument, options)
}
export function useListWorkspacesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<ListWorkspacesQuery, ListWorkspacesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListWorkspacesQuery, ListWorkspacesQueryVariables>(ListWorkspacesDocument, options)
}
export type ListWorkspacesQueryHookResult = ReturnType<typeof useListWorkspacesQuery>;
export type ListWorkspacesLazyQueryHookResult = ReturnType<typeof useListWorkspacesLazyQuery>;
export type ListWorkspacesQueryResult = Apollo.QueryResult<ListWorkspacesQuery, ListWorkspacesQueryVariables>;
export const ListWorkspacesWithUserCountDocument = gql`
    query ListWorkspacesWithUserCount {
  listWorkspaces {
    ...WorkspaceListFragment
    usersCount
  }
}
    ${WorkspaceListFragmentFragmentDoc}`

/**
 * __useListWorkspacesWithUserCountQuery__
 *
 * To run a query within a React component, call `useListWorkspacesWithUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkspacesWithUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkspacesWithUserCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkspacesWithUserCountQuery (baseOptions?: Apollo.QueryHookOptions<ListWorkspacesWithUserCountQuery, ListWorkspacesWithUserCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListWorkspacesWithUserCountQuery, ListWorkspacesWithUserCountQueryVariables>(ListWorkspacesWithUserCountDocument, options)
}
export function useListWorkspacesWithUserCountLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<ListWorkspacesWithUserCountQuery, ListWorkspacesWithUserCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListWorkspacesWithUserCountQuery, ListWorkspacesWithUserCountQueryVariables>(ListWorkspacesWithUserCountDocument, options)
}
export type ListWorkspacesWithUserCountQueryHookResult = ReturnType<typeof useListWorkspacesWithUserCountQuery>;
export type ListWorkspacesWithUserCountLazyQueryHookResult = ReturnType<typeof useListWorkspacesWithUserCountLazyQuery>;
export type ListWorkspacesWithUserCountQueryResult = Apollo.QueryResult<ListWorkspacesWithUserCountQuery, ListWorkspacesWithUserCountQueryVariables>;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($input: CreateWorkspaceInput!) {
  createWorkspace(input: $input) {
    ...WorkspaceListFragment
  }
}
    ${WorkspaceListFragmentFragmentDoc}`
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceMutation (baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options)
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    ...WorkspaceDetailFragment
  }
}
    ${WorkspaceDetailFragmentFragmentDoc}`
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation (baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options)
}
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const DeleteWorkspaceDocument = gql`
    mutation DeleteWorkspace($id: ID!) {
  deleteWorkspace(id: $id) {
    ...WorkspaceListFragment
  }
}
    ${WorkspaceListFragmentFragmentDoc}`
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation (baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>(DeleteWorkspaceDocument, options)
}
export type DeleteWorkspaceMutationHookResult = ReturnType<typeof useDeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationResult = Apollo.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const UpdateWorkspaceUserDocument = gql`
    mutation UpdateWorkspaceUser($input: UpdateWorkspaceUserInput!) {
  updateWorkspaceUser(input: $input) {
    ...WorkspaceUserFragment
  }
}
    ${WorkspaceUserFragmentFragmentDoc}`
export type UpdateWorkspaceUserMutationFn = Apollo.MutationFunction<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>;

/**
 * __useUpdateWorkspaceUserMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceUserMutation, { data, loading, error }] = useUpdateWorkspaceUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceUserMutation (baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>(UpdateWorkspaceUserDocument, options)
}
export type UpdateWorkspaceUserMutationHookResult = ReturnType<typeof useUpdateWorkspaceUserMutation>;
export type UpdateWorkspaceUserMutationResult = Apollo.MutationResult<UpdateWorkspaceUserMutation>;
export type UpdateWorkspaceUserMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>;
export const DeleteWorkspaceUserDocument = gql`
    mutation DeleteWorkspaceUser($id: ID!) {
  deleteWorkspaceUser(id: $id) {
    ...WorkspaceUserFragment
  }
}
    ${WorkspaceUserFragmentFragmentDoc}`
export type DeleteWorkspaceUserMutationFn = Apollo.MutationFunction<DeleteWorkspaceUserMutation, DeleteWorkspaceUserMutationVariables>;

/**
 * __useDeleteWorkspaceUserMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceUserMutation, { data, loading, error }] = useDeleteWorkspaceUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkspaceUserMutation (baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceUserMutation, DeleteWorkspaceUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteWorkspaceUserMutation, DeleteWorkspaceUserMutationVariables>(DeleteWorkspaceUserDocument, options)
}
export type DeleteWorkspaceUserMutationHookResult = ReturnType<typeof useDeleteWorkspaceUserMutation>;
export type DeleteWorkspaceUserMutationResult = Apollo.MutationResult<DeleteWorkspaceUserMutation>;
export type DeleteWorkspaceUserMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceUserMutation, DeleteWorkspaceUserMutationVariables>;
export const InviteUserToWorkspaceDocument = gql`
    mutation InviteUserToWorkspace($input: InviteUserToWorkspaceInput!) {
  inviteUserToWorkspace(input: $input) {
    ...WorkspaceDetailFragment
  }
}
    ${WorkspaceDetailFragmentFragmentDoc}`
export type InviteUserToWorkspaceMutationFn = Apollo.MutationFunction<InviteUserToWorkspaceMutation, InviteUserToWorkspaceMutationVariables>;

/**
 * __useInviteUserToWorkspaceMutation__
 *
 * To run a mutation, you first call `useInviteUserToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToWorkspaceMutation, { data, loading, error }] = useInviteUserToWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserToWorkspaceMutation (baseOptions?: Apollo.MutationHookOptions<InviteUserToWorkspaceMutation, InviteUserToWorkspaceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteUserToWorkspaceMutation, InviteUserToWorkspaceMutationVariables>(InviteUserToWorkspaceDocument, options)
}
export type InviteUserToWorkspaceMutationHookResult = ReturnType<typeof useInviteUserToWorkspaceMutation>;
export type InviteUserToWorkspaceMutationResult = Apollo.MutationResult<InviteUserToWorkspaceMutation>;
export type InviteUserToWorkspaceMutationOptions = Apollo.BaseMutationOptions<InviteUserToWorkspaceMutation, InviteUserToWorkspaceMutationVariables>;
export const SetWorkspaceSubscriptionDocument = gql`
    mutation SetWorkspaceSubscription($input: SetWorkspaceSubscriptionInput!) {
  setWorkspaceSubscription(input: $input) {
    ...WorkspaceDetailFragment
  }
}
    ${WorkspaceDetailFragmentFragmentDoc}`
export type SetWorkspaceSubscriptionMutationFn = Apollo.MutationFunction<SetWorkspaceSubscriptionMutation, SetWorkspaceSubscriptionMutationVariables>;

/**
 * __useSetWorkspaceSubscriptionMutation__
 *
 * To run a mutation, you first call `useSetWorkspaceSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspaceSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspaceSubscriptionMutation, { data, loading, error }] = useSetWorkspaceSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWorkspaceSubscriptionMutation (baseOptions?: Apollo.MutationHookOptions<SetWorkspaceSubscriptionMutation, SetWorkspaceSubscriptionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetWorkspaceSubscriptionMutation, SetWorkspaceSubscriptionMutationVariables>(SetWorkspaceSubscriptionDocument, options)
}
export type SetWorkspaceSubscriptionMutationHookResult = ReturnType<typeof useSetWorkspaceSubscriptionMutation>;
export type SetWorkspaceSubscriptionMutationResult = Apollo.MutationResult<SetWorkspaceSubscriptionMutation>;
export type SetWorkspaceSubscriptionMutationOptions = Apollo.BaseMutationOptions<SetWorkspaceSubscriptionMutation, SetWorkspaceSubscriptionMutationVariables>;